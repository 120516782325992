<template>
  <div class="loading-indicator">
    <figure class="icon loading-icon">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(224, 102, 102, 0); display: block;" width="250px" height="250px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="14" r="40" stroke-dasharray="188.49555921538757 64.83185307179586">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.6666666666666666s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
};
</script>

<style lang="stylus">
.loading-indicator {
  display flex
  align-items center
  margin auto
  max-width 60px
  max-height 60px

  svg {
    max-width 60px
    max-height 60px
  }
}
</style>
